export default function(toggleElementID, passwordElementID) {
  const showPasswordToggle = document.querySelector('#' + toggleElementID);

  if (showPasswordToggle) {
    showPasswordToggle.addEventListener('click', function(event) {
      const newType = event.currentTarget.checked ? 'text' : 'password';
      document.querySelector(`#${passwordElementID}`).setAttribute('type', newType);
    });
  }
}
