/* eslint no-unused-vars: 0 */

import jQuery from './jquery';
import * as transition from 'bootstrap-sass/assets/javascripts/bootstrap/transition';
import * as alert from 'bootstrap-sass/assets/javascripts/bootstrap/alert';
import * as button from 'bootstrap-sass/assets/javascripts/bootstrap/button';
import * as carousel from 'bootstrap-sass/assets/javascripts/bootstrap/carousel';
import * as collapse from 'bootstrap-sass/assets/javascripts/bootstrap/collapse';
import * as dropdown from 'bootstrap-sass/assets/javascripts/bootstrap/dropdown';
import * as modal from 'bootstrap-sass/assets/javascripts/bootstrap/modal';
import * as tooltip from 'bootstrap-sass/assets/javascripts/bootstrap/tooltip';
import * as popover from 'bootstrap-sass/assets/javascripts/bootstrap/popover';
import * as scrollspy from 'bootstrap-sass/assets/javascripts/bootstrap/scrollspy';
import * as tab from 'bootstrap-sass/assets/javascripts/bootstrap/tab';
import * as affix from 'bootstrap-sass/assets/javascripts/bootstrap/affix';

export {
  affix,
  alert,
  button,
  carousel,
  collapse,
  dropdown,
  modal,
  tooltip,
  popover,
  scrollspy,
  tab,
  transition
};
