import jQuery from 'jquery';
import * as bootstrap from './shims/bootstrap';
import togglePassword from './enrollment/toggle-password';

bootstrap; // hack to make eslint happy after importing shims

togglePassword('togglePassword', 'password');
togglePassword('togglePassword', 'confirm-password');

jQuery(document).ready(function() {
  jQuery('.navbar-toggle').click(function() {
    jQuery('#mobile-sidebar-wrapper').toggleClass('toggled');
  });

  jQuery('.navbar-toggle').click(function() {
    if (jQuery('.navbar-toggle').attr('aria-expanded') === 'false') {
      jQuery('.overlay-container').removeClass('toggled');
      jQuery('.list-toggle').removeClass('toggled');
      jQuery('.event-indicator').removeClass('toggled');
      jQuery('.navigator-left-mobile').removeClass('toggled');
      jQuery('.navigator-right-mobile').removeClass('toggled');
      jQuery('.trip-list').removeClass('toggled');
      jQuery('.mobile-trips-list').removeClass('toggled');
      jQuery('.events-list-mobile').removeClass('toggled');
    } else {
      jQuery('.overlay-container').addClass('toggled');
      jQuery('.list-toggle').addClass('toggled');
      jQuery('.event-indicator').addClass('toggled');
      jQuery('.navigator-left-mobile').addClass('toggled');
      jQuery('.navigator-right-mobile').addClass('toggled');
    }
  });

  jQuery('.loading-sample').click(function() {
    jQuery('.loading-overlay').toggleClass('hidden');
    setTimeout(function(){
      jQuery('.loading-overlay').toggleClass('hidden');
    }, 5000);
  });

  

  /* CHECKBOX */
  jQuery('input[type=checkbox]').change(function(){
    if(jQuery(this).is(':checked')) {
      var elName = jQuery(this).attr('name');

      setTimeout(function() {
        jQuery('[data-label-for=' + elName + ']').addClass('remove-left-padding');
        jQuery('[data-check-for=' + elName + ']').removeClass('hidden');
        setTimeout(function(){
          jQuery('[data-label-for=' + elName + ']').removeClass('remove-left-padding');
          jQuery('[data-check-for=' + elName + ']').addClass('hidden');
        }, 2000);
      }, 500);
    }
  });

  /* ACCORDION */
  if (jQuery('.accordion').length) {
    jQuery('.accordion__section').on('click', function(event) {
      jQuery(event.delegateTarget).toggleClass('active');
      jQuery(this).children().last().slideToggle();
    });
  }

  /* MATCH LOGIN BUTTON WIDTHS */
  if (jQuery('.navbar-btn').length && window.innerWidth > 860) {
    var navbarButtons = jQuery('.navbar-btn');
    var buttonWidths = navbarButtons.map(function() {
      return jQuery(this).width();
    });
    var maxWidth = Math.max.apply(null, buttonWidths);

    navbarButtons.each(function() {
      var button = jQuery(this);
      if (button.width() < maxWidth) {
        button.width(maxWidth);
      }
    });
  }

});
